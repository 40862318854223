import _ from "lodash";
import LoadAllDependencies from "./sso/InitSSO";
import AddRecaptcha from "./google/recaptcha";
import initSlideShow from "./markting/slideshow";
import { initLoadBalancingTsPlus, ShouldUseMegaLoadBalancer } from "./loadbalancer/loadbalancer";
import { createSpan, endSpan } from './opentelemetry/otel-instrumentation'

window.mainSpan = createSpan('Index')

async function Init(parent) {
  const span = createSpan('Init', parent)
  await LoadAllDependencies(span);
  await initSlideShow(span);
  AddRecaptcha(span);
  endSpan(span);
}

async function TryInit(parent) {
  const span = createSpan('TryInit', parent)
  let scriptElement = document.getElementById("sso-script-no-init");
  if (!scriptElement) {
    await Init(span);
  }


  endSpan(span)
}

await TryInit(window.mainSpan);

// Login by TSPlus
if (!window.isTsPlusLoadBalanceOnly && ShouldUseMegaLoadBalancer(window.mainSpan)) {
  window.initLoadBalancing = function () {
    initLoadBalancingTsPlus(window.mainSpan);
  }
}

endSpan(window.mainSpan)
