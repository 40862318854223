﻿const displayNone = "display: none;";
import gifLoader from './img/loading.gif';

export function loading() {
    const divCenter = document.getElementById("divcenter");
    if (divCenter) {
        divCenter.style.setProperty("display", "block", "important");
        const paragrafElement = divCenter.getElementsByTagName("p");
        for (let item of paragrafElement) {
            item.style = displayNone;
        }

        changeFormTitle('ACESSANDO A MEGA CLOUD');

        const logonformidElement = document.createElement("logonformid");
        if (logonformidElement) {
            HideInputs();
            document.getElementById("logonformid").style = displayNone;

            CrateImgLoading(divCenter, logonformidElement);
        }
    }
}

function CrateImgLoading(divCenter, logonformidElement) {
    const imgLoader = document.createElement("img");
    imgLoader.id = "imgLoginLoader";
    imgLoader.src = gifLoader;
    imgLoader.classList.add("imgLoader");
    imgLoader.classList.add("elementCentered");
    divCenter.appendChild(imgLoader);
    logonformidElement.style = displayNone;
    const elementLoader = document.createElement("h5");
    elementLoader.id = "lbLoader";
    elementLoader.innerText = "Validando informações de acesso";
    elementLoader.classList.add('elementCentered');
    divCenter.appendChild(elementLoader);
}

export function CreateKeyCloakLogOffButton(url) {
    const divCenter = document.getElementById("divcenter");
    const elementBtnLogout = document.createElement("a");
    elementBtnLogout.href = url;
    const buttonElement = document.createElement("button");
    buttonElement.innerText = "Sair";
    buttonElement.classList.add("elementCentered");
    buttonElement.classList.add('buttonKeycloak');
    elementBtnLogout.appendChild(buttonElement);
    divCenter.appendChild(elementBtnLogout);
}

export function HideInputs() {

    HideElement(document.getElementById("Editbox1"));
    HideElement(document.getElementById("Editbox2"));
    HideElement(document.getElementById("buttonLogOn"));
    TimerCheckHideSpans();
}

function HideElement(element) {
    if (element) {
        element.style = displayNone;
        for (let item of element.parentElement.getElementsByTagName("label")) {
            item.style = displayNone;
        }
    }
}

function HideSpans() {
    const userSpanOk = document.getElementById("span-login-ok");
    userSpanOk.style = displayNone;
    const userSpanKo = document.getElementById("span-login-ko");
    userSpanKo.style = displayNone;
    const passwordOk = document.getElementById("span-password-ok");
    passwordOk.style = displayNone;
    const passwordKo = document.getElementById("span-password-ko");
    passwordKo.style = displayNone;

}

var checkSpanVisibleState;
const maxSpanAttempts = 150;
var countSpanAttempts = 0;

function TimerCheckHideSpans() {
    countSpanAttempts++;
    if (countSpanAttempts < maxSpanAttempts) {
        checkSpanVisibleState = setInterval(() => {
            HideSpans();
            TimerCheckHideSpans();
        }, 30);
    } else {
        if (checkSpanVisibleState)
            clearInterval(checkSpanVisibleState)
    }
}

function sleep(milliseconds) {
    const date = Date.now();
    let currentDate = null;
    do {
        currentDate = Date.now();
    } while (currentDate - date < milliseconds);
}

export function setLoginValues(user, pass) {

    const userInput = document.getElementById("Editbox1");
    userInput.value = user;

    const userpassword = document.getElementById("Editbox2");
    userpassword.value = pass;
}

export function WriteKeycloakUserName(username) {
    const elmentLoader = document.getElementById("lbLoader");
    elmentLoader.innerText = `${elmentLoader.innerText} do usuário ${username}`;
}

export function CreateErroMsg(responseText) {
    hideLoader();
    let lbLoader = document.getElementById('lbLoader');
    lbLoader.style.color = 'red';
    lbLoader.innerText = "Erro ao recuperar as informações de credenciais no servidor remoto";
    const buttonElement = document.createElement("button");
    buttonElement.type = 'button';
    buttonElement.innerText = "Mais detalhes";
    buttonElement.classList.add("collapsible");
    const divContentElement = document.createElement("div");
    divContentElement.classList.add("content");
    const paragrElement = document.createElement("p");
    paragrElement.innerText = responseText;
    paragrElement.classList.add('subContent');
    divContentElement.appendChild(paragrElement);

    buttonElement.addEventListener("click", function() {

        this.classList.toggle("active");
        var content = this.nextElementSibling;
        if (content.style.display === "block") {
            ExpandAndRetract(this, content, false);
        } else {
            ExpandAndRetract(this, content, true);
        }
    });

    lbLoader.appendChild(buttonElement);
    lbLoader.appendChild(divContentElement);
}

export function CreateWarningErroMsg(msg, tittle) {
    let lbLoader = document.getElementById('lbLoader');
    lbLoader.innerHTML = msg;
    hideLoader();    
    const divAlert = document.createElement('div');
    divAlert.classList.add('alert');
    divAlert.classList.add('alert-warning');
    divAlert.classList.add('alert-dismissible');
    divAlert.classList.add('fade');
    divAlert.classList.add('show');
    divAlert.setAttribute('role', 'alert');
    divAlert.style.width = '80%';
    divAlert.style.margin = 'auto';
    divAlert.style.position = 'fixed';
    divAlert.style.zIndex = 9999;
    divAlert.style.left = '10%';
    divAlert.style.right = '10%';

    const buttonClose = document.createElement('button');
    buttonClose.type = 'button';
    buttonClose.classList.add('close');
    buttonClose.setAttribute('araia-label','Fechar');
    buttonClose.setAttribute ('data-dismiss','alert');
    const span = document.createElement('span');
    span.setAttribute('aria-hidden', 'true');
    span.innerHTML = 'X';
    buttonClose.appendChild(span);
    divAlert.appendChild(buttonClose);
  
    if (tittle) {
      const hElement = document.createElement('h4');
      hElement.innerText = tittle;
      hElement.classList.add('alert-heading');
      divAlert.appendChild(hElement);
    }
  
    const pElement = document.createElement('p');
    pElement.innerText = msg;
    divAlert.appendChild(pElement);
  
    document.body.insertAdjacentElement('afterbegin', divAlert);
}

var operators = {
    '+': function(a, b) { return a + b },
    '-': function(a, b) { return a - b }
}

function ExpandAndRetract(button, content, expand) {
    const increment = 300;
    const op = expand ? operators["+"] : operators["-"];
    const opInverted = expand ? operators["-"] : operators["+"];
    const parent = document.getElementById('divcenter').parentElement;
    const wd = op(parent.clientWidth, increment);
    content.style.display = expand ? "block" : "none";
    var divcenterstyle = parent.currentStyle || window.getComputedStyle(parent);
    parent.style.width = wd + "px";
}


export function hideLoader() {
    const imgLoader = document.getElementById("imgLoginLoader");
    if (imgLoader)
        imgLoader.style.display = "none";
}


export function showConnected(username) {
    let lbLoader = document.getElementById('lbLoader');
    lbLoader.innerText = !!username ? `Sessão iniciada para ${username}` : "Sessão iniciada";
    changeFormTitle('MEGA CLOUD');
}

function changeFormTitle(msg) {
    const divCenter = document.getElementById("divcenter");
    for (let item of divCenter.parentElement.getElementsByTagName('h2')) {
        item.innerText = msg;
    }
}
