﻿import * as UIChange from "./UIChange.js";
import Keycloak from "keycloak_js";
import GetRealm from "./realm";
import { callbalancer } from "../loadbalancer/loadbalancer";
import { createSpan, endSpan } from "../opentelemetry/otel-instrumentation.js";
import { verifyTenantStatus } from "../login-default/validations/verifyTenantStatus.js";
import { SpanStatusCode } from '@opentelemetry/api';

let urlKeyCloakProvider = "https://key.megaerp.online";
let urlKeyCloakAuth;
let keycloakInstancia;
var sso;

function CreateKeycloak(parent) {
  const span = createSpan('CreateKeycloak', parent)
  const realm = GetRealm(span);
  var config = ssoConfiguration || {};
  sso = {
    isSSO: config[realm]?.isSSO || false,
    realm: config[realm]?.realm || realm,
    clientId: config[realm]?.clientId || "mega-cloud",
    isLoadBalancer: config[realm]?.isLoadBalancer ?? true,
    farmUrl: config[realm]?.farmUrl || window.location.host,
    openMega: config[realm]?.openMega ?? true,
  };
  urlKeyCloakProvider = ssoConfiguration.authUrl;
  urlKeyCloakAuth = urlKeyCloakProvider + "/auth";

  keycloakInstancia = new Keycloak({
    url: urlKeyCloakAuth,
    realm: sso.realm,
    clientId: sso.clientId,
    enablecors: true,
  });
  endSpan(span)
}

export default function ExecSSO(parent) {
  const span = createSpan('ExecSSO', parent)
  CreateKeycloak(span);
  keycloakInstancia
    .init({})
    .then(async function (authenticated) {
      if (!authenticated) {
        keycloakInstancia.login();
      } else {
        UIChange.loading(span);
        UIChange.WriteKeycloakUserName(keycloakInstancia.idTokenParsed.name, span);
        const keycloakLogOffUrl = `${urlKeyCloakAuth}/realms/${keycloakInstancia.realm}/protocol/openid-connect/logout?post_logout_redirect_uri=${window.location}&id_token_hint=${keycloakInstancia.idToken}`;
        UIChange.CreateKeyCloakLogOffButton(keycloakLogOffUrl, span);
        await Logon(span);
      }
    })
    .catch(function (e) {
      UIChange.hideLoader(span);
      span.setStatus({
        code: SpanStatusCode.ERROR,
        message: `${e}`
      });
      endSpan(span)
      throw new Error(e);
    });
  endSpan(span)
}

async function Logon(parent) {
  const span = createSpan('Logon', parent)
  verifyLogonValues(span);
  ByPassLogon(span);
  try {
    const statusTenant = await verifyTenantStatus("SSO", keycloakInstancia.idTokenParsed.preferred_username, span)
    if (!statusTenant) {
      UIChange.CreateWarningErroMsg("Sistema está indisponível ou sendo atualizado. Tente novamente mais tarde.", "ATENÇÃO!", span);
      return;
    }
    const balancer = await callbalancer(true, span);
    LoginTsClient(balancer, keycloakInstancia, span);
  } catch (error) {
    console.log(error);
    span.setStatus({
      code: SpanStatusCode.ERROR,
      message: `${error}`
    });
    UIChange.CreateErroMsg(`Algo deu de errado ao acessar o servidor: ${error}`,span);
    endSpan(span)
  }
  endSpan(span)
}

function verifyLogonValues(parent) {
  const span = createSpan('verifyLogonValues', parent)
  try {
    if (
      !keycloakInstancia.idTokenParsed.mega_cloud_username ||
      !keycloakInstancia.idTokenParsed.mega_cloud_password
    ) {
      span.setStatus({
        code: SpanStatusCode.ERROR,
        message: `Usuário com as credenciais de acesso configuradas incorretamente`
      });
      endSpan(span)
      throw "Usuário com as credenciais de acesso configuradas incorretamente";
    }

    UIChange.setLoginValues(
      keycloakInstancia.idTokenParsed.mega_cloud_username,
      keycloakInstancia.idTokenParsed.mega_cloud_password,
      span
    );
  } catch (error) {
    console.log(error);
    span.setStatus({
      code: SpanStatusCode.ERROR,
      message: `${error}`
    });
    endSpan(span)
    throw error;
  }
  endSpan(span)
}

function LoginTsClient(farmUrl, keycloakInstancia, parent) {
  const span = createSpan('LoginTsClient', parent)
  const spanContext = span.spanContext()
  const traceId = spanContext.traceId
  const spanId = spanContext.spanId
  let url = `https://${farmUrl}/software/html5.html?user=${keycloakInstancia.idTokenParsed.mega_cloud_username}&pwd=${keycloakInstancia.idTokenParsed.mega_cloud_password}&domain=megacloud`;
  if (sso.openMega) {
    url = `${url}&program=C:\\\\MegaERPLauncher\\\\Mega.ERP.Auth.Launcher.App.exe&startupdir=C:\\\\MegaERPLauncher&params=${keycloakInstancia.token},${keycloakInstancia.refreshToken},Bearer,-999,${traceId},${spanId}`;
  }
  window.open(url);
  endSpan(span)
}

function ByPassLogon(parent) {
  const span = createSpan('ByPassLogon', parent)
  try {
    try {
      initHtmlPage();
    } catch (error) {
      span.setStatus({
        code: SpanStatusCode.ERROR,
        message: `${error}`
      });
      endSpan(span)
    }
    mainPortalInit();
    pass_original = window.pass;
    login_original = window.user;

    getOwnImplementation();

    UIChange.HideInputs(span);
    twoStepStatus = "disabled";
    UIChange.hideLoader(span);
    UIChange.showConnected(keycloakInstancia.idTokenParsed.name, span);
    endSpan(span)
  } catch (e) {
    span.setStatus({
      code: SpanStatusCode.ERROR,
      message: `${e}`
    });
    if (!CheckAndNotifyPopupBlocked(e,span)) throw new Error(e);
    endSpan(span)
  }

  function CheckAndNotifyPopupBlocked(e,parent) {
    const span = createSpan('CheckAndNotifyPopupBlocked',parent)
    if (e.message == "Cannot read property 'document' of null") {
      const msg =
        "Por favor configurar no navegador o desbloqueio do popup para esse site, logo em seguida faça o recarregamento do mesmo para dar sequencia no processo";
      UIChange.CreateWarningErroMsg(msg, "Popup Bloqueado");
      endSpan(span)
      return true;
    }
    endSpan(span)
    return false;
  }
}
