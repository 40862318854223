
const BASE_URL = 'http://172.20.253.14/metrics';

async function fetchAPI(endpoint, options = {}) {
    const url = `${BASE_URL}${endpoint}`;
    const response = await fetch(url, options);

    if (!response.ok) {
        throw new Error(`Erro: ${response.status} ${response.statusText}`);
    }

    return response.json();
}

export function startMetricsLoginSucess(status_code) {
    const user_login = document.getElementById("Editbox1").value.toLowerCase();
    const status_code_string = String(status_code);
    const attributes = { status_login: true, host: document.location.host, user_agent: navigator.userAgent, status_code: status_code_string, user_login: user_login};
    fetchAPI('/CounterLogin', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'ts-plus-teste'
        },
        body: JSON.stringify(attributes),
    });
}

export function startMetricsLoginSucessSSO(user_login) {
    const attributes = { status_login: true, host: document.location.host, user_agent: navigator.userAgent, status_code: "200", user_login: user_login};
    fetchAPI('/CounterLogin', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'ts-plus-teste'
        },
        body: JSON.stringify(attributes),
    });
}

export function startMetricsLoginLatency(status_code, responseTime) {

    const status_code_string = String(status_code);
    const attributes = { status_login: true, host: document.location.host, user_agent: navigator.userAgent, status_code: status_code_string, response_time: responseTime };

    fetchAPI('/GaugeLatencyLogin', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'ts-plus-teste'
        },
        body: JSON.stringify(attributes),
    });
}

export function startMetricsLoginFail(error, status_code) {
    const user_login = document.getElementById("Editbox1").value.toLowerCase();
    const status_code_string = String(status_code);
    const attributes = { status_login: false, host: document.location.host, user_agent: navigator.userAgent, error: error, status_code: status_code_string, user_login: user_login };
    fetchAPI('/CounterLogin', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'ts-plus-teste'
        },
        body: JSON.stringify(attributes),
    });
}

export function startMetricsLoginFailSSO(error, user_login) {
    const attributes = { status_login: false, host: document.location.host, user_agent: navigator.userAgent, error: error, status_code: "500", user_login: user_login };
    fetchAPI('/CounterLogin', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'ts-plus-teste'
        },
        body: JSON.stringify(attributes),
    });
}

export async function startMetricsLoadBalanceSucess(LoadedServerName, status_code) {
    const status_code_string = String(status_code);
    const attributes = { status_lb: true, host: document.location.host, user_agent: navigator.userAgent, loadedservername: LoadedServerName, status_code: status_code_string };
    fetchAPI('/CounterLoadBalanceTSPlus', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'ts-plus-teste'
        },
        body: JSON.stringify(attributes),
    });
}

export async function startMetricsLoadBalanceLatency(status_code, responseTime,lb) {
    const status_code_string = String(status_code);
    const attributes = { status_lb: true, host: document.location.host, user_agent: navigator.userAgent, status_code: status_code_string, lb: lb, response_time: responseTime };

    fetchAPI('/GaugeLatencyLoadBalance', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'ts-plus-teste'
        },
        body: JSON.stringify(attributes),
    });
}

export function startMetricsLoadBalanceFail(error, status_code) {
    const status_code_string = String(status_code);
    const attributes = { status_lb: false, host: document.location.host, user_agent: navigator.userAgent, error: error, status_code: status_code_string };
    fetchAPI('/CounterLoadBalanceTSPlus', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'ts-plus-teste'
        },
        body: JSON.stringify(attributes),
    });
}
